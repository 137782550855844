.profile_container {
    width: 100%;
    max-width: 900px;
    margin: auto;
    padding-bottom: 60px;
}

.profile_header {
    display: flex;
    padding: 20px 0;
    border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.profile_body {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    min-height: 400px;
}

.profile_sidebar {
    width: 100%;
    max-width: 150px;
    flex: 0 0 150px;
}

.profile_content {
    width: 100%;
    max-width: calc(100% - 150px);
    flex: 0 0 calc(100% - 150px);
    padding-left: 40px;
}

.profile_sidebar button {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    border-radius: 0 5px 0 5px;
    margin-bottom: 5px;
}

.profile_sidebar button.active {
    background-color: rgba(8, 42, 107, 0.1);
}

.detail_form {
    width: 100%;
    max-width: 620px;
}

.detail_form .form-group {
    display: flex;
    align-items: center;
}

.detail_form .form-group>label {
    width: 40%;
    flex: 0 0 40%;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font);
    margin: 0;
}

.detail_form .form-group .form-input {
    width: 60%;
    flex: 0 0 60%;
    display: flex;
    position: relative;
}

.detail_form .form-group .form-input .right_btn {
    position: absolute;
    right: 15px;
    top: 45%;
    transform: translateY(-50%);
}

.address_box {
    margin-bottom: 20px;
    border: rgba(0, 0, 0, 0.1) 1px solid;
    border-radius: 10px;
    padding: 15px;
}

.profile_footer {
    display: flex;
    border-top: rgba(0, 0, 0, 0.1) 1px solid;
    justify-content: flex-end;
    padding-top: 20px;
}

@media screen and (max-width: 992px) {
    .profile_container {
        max-width: 720px;
    }
}

@media screen and (max-width: 767px) {
    .profile_container {
        max-width: 540px;
        padding-inline: 15px;
    }

    .profile_body {
        flex-direction: column;
    }

    .profile_sidebar {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        margin-bottom: 20px;
    }

    .profile_content {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 0;
    }

    .detail_form {
        max-width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .detail_form .form-group {
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 10px;
        gap: 10px;
    }
    .detail_form .form-group .form-input {
        width: 100%;
        flex: 0 0 100%;
    }
    .profile_sidebar button {
        font-size: 12px;
        padding: 10px 10px;
        text-align: center;
    }
}