header {
    position: relative;
}


.header_inner {
    display: flex;
    align-items: center;
}

.submenu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    display: none;
    background-color: #fff;
    z-index: 99;
    padding: 15px 0 30px;
    border-top: #efefef 1px solid;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 5px;
}

.main_nav {
    display: flex;
}


.main_nav>li>a img {
    transition: ease 0.3s;
}


.main_nav>li>a {
    display: inline-block;
    padding: 25px 20px;
    white-space: nowrap;
}

.main_nav a {
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
}

.submenu a:not(.color_menu) {
    border-bottom: transparent 1px solid;
}

.submenu a:hover {
    border-bottom-color: var(--primary);
    color: var(--primary) !important;
}

.color_dot {
    width: 14px;
    height: 14px;
    flex: 0 0 14px;
    border-radius: 50%;
    display: inline-block;
}

.color_menu {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--text) !important;
    font-size: 12px;
    margin-bottom: 5px;
}

.header_search {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #efefef;
    border-radius: 10px;
    padding: 0 15px;
    margin: 0 20px;
    position: relative;
}

.header_accounts {
    white-space: nowrap;
    display: flex;
}

.header_accounts>a,
.header_accounts button {
    margin-left: 30px;
}

.header_dropdown .btn {
    background-color: transparent !important;
    padding: 0;
    border: 0;
}

.header_dropdown .btn:after {
    display: none;
}

.header_dropdown .dropdown-menu {
    inset: 10px 0px auto auto !important;
    border-radius: 10px;
}

.header_dropdown .dropdown-item {
    color: var(--primary);
    font-weight: 500;
    font-size: 14px;
}

.header_dropdown .dropdown-item>span {
    width: 30px;
    display: inline-block;
}

.search_dropdown {
    position: absolute;
    inset: 110% auto auto 0;
    background-color: #fff;
    border-radius: 10px;
    z-index: 9;
    min-width: 480px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px;
    border: rgba(0, 0, 0, 0.1) 1px solid;
}

.most_search_item {
    border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
    padding: 10px 20px;
    display: block;
}

.most_search_item>span {
    width: 40px;
    display: inline-block;
}

/* Footer */
footer {
    background-color: var(--primary);
    color: #fff;
}

footer p {
    color: #fff;
}

footer ul li {
    margin-bottom: 6px;
    font-size: 14px;
}

footer a {
    border-bottom: transparent 1px solid;
}

footer a:hover {
    border-bottom-color: #fff;
}

.footer_nav {
    padding: 60px 0;
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
}

.footer_bottom {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_bottom p {
    color: var(--peach);
}

.footer_social a {
    margin-left: 10px;
    padding-left: 10px;
    position: relative;
    top: 0;
}

.footer_social a:hover {
    top: -3px
}

.footer_social a {
    border-bottom: 0;
}

.contact_address {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contact_icon {
    width: 60px;
    flex: 0 0 60px;
}

.contact_icon img {
    max-width: 40px;
}

.contact_address_outer {
    position: relative;
}

.contact_address_outer:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    pointer-events: none;
    border-right: rgba(0, 0, 0, 0.1) 1px solid;
}

.cart_sidebar_outer:not(.show) {
    display: none;
}

.cart_sidebar {
    width: 100%;
    max-width: 400px;
    position: fixed;
    inset: 0 0 0 auto;
    background-color: #fff;
    z-index: 9999;
    padding: 25px 40px  9px;
    display: flex;
    flex-direction: column;
}

.cart_backdrop {
    position: fixed;
    inset: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 999;
    cursor: pointer;
}

.cart_block {
    max-height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
}

.cart_item {
    padding-top: 10px;
}

.cart_item_img img {
    border-radius: 10px;
}

.empty_cart {
    text-align: center;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav_toggle {
    display: none;
    width: 25px;
    position: relative;
    z-index: 999;
}

.nav_toggle span {
    position: relative;
    width: 25px;
    height: 4px;
    background-color: var(--primary);
    border-radius: 10px;
    display: block;
    margin-left: auto;
    transition: ease 0.2s;
}

.nav_toggle span+span {
    margin-top: 4px;
}

.nav_toggle span:nth-child(2) {
    width: 20px;
}

.nav_toggle span:nth-child(3) {
    width: 15px;
}

.show_nav .nav_toggle span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
}

.show_nav .nav_toggle span:nth-child(2) {
    opacity: 0;
}

.show_nav .nav_toggle span:nth-child(3) {
    transform: rotate(-45deg);
    width: 25px;
    top: -8px;
}

@media screen and (min-width: 991px) {
    .main_nav>li:hover>.submenu {
        display: block;
    }

    .main_nav>li:hover>a img {
        transform: rotate(180deg);
    }
}

@media screen and (max-width: 1199px) {
    .search_dropdown {
        left: auto;
        right: 0;
    }

    .main_nav>li>a {
        padding-inline: 15px;
    }

    .header_logo img {
        max-width: 140px;
    }

    .header_accounts>a,
    .header_accounts button {
        margin-left: 20px;
    }
}

@media screen and (max-width: 991px) {
    .header_inner {
        padding: 10px 0;
    }

    .header_navbar {
        position: fixed;
        inset: 0;
        background: #fff;
        z-index: 999;
        opacity: 0;
        transform: translateY(-110%);
        transition: ease 0.3s;
        visibility: hidden;
    }

    .header_accounts {
        position: fixed;
        bottom: 10px;
        left: 10px;
        background: #fff;
        justify-content: center;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
        right: 10px;
        border-radius: 10px;
        z-index: 9;
    }

    .nav_toggle {
        display: block;
    }

    .show_nav {
        overflow: hidden;
    }

    .show_nav .header_navbar {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .submenu {
        position: relative;
    }

    .show .submenu {
        display: block;
    }

    .main_nav>li>a>button.caret {
        padding-left: 20px;
    }

    .main_nav>li>a>button.caret img {
        width: 12px;
    }

    .show>a img {
        transform: rotate(180deg);
    }

    .main_nav {
        flex-direction: column;
        padding: 40px 0;
        max-height: calc(100% - 80px);
        overflow: hidden;
        overflow-y: auto;
        max-width: 720px;
        margin: auto;
    }

    .submenu .container {
        max-width: 100%;
    }

    .main_nav>li>a {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .main_nav a:not(.color_menu) {
        font-size: 16px;
    }

    .footer_nav {
        padding: 20px 0;
    }

    .footer_bottom {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .footer_logo img {
        max-width: 220px;
    }

    .header_accounts>a,
    .header_accounts button {
        margin: 0 20px;
    }

    .header_accounts>a img,
    .header_accounts button>img {
        width: 20px;
    }
    .main_nav li {
        position: relative;
    }
}


@media screen and (max-width: 767px) {
    .main_nav {
        max-width: 520px;
    }

    .contact_address_outer::after {
        display: none;
    }
}

@media screen and (max-width: 575px) {
    .header_logo img {
        max-width: 120px;
    }

    .search_dropdown {
        min-width: 300px;
    }

    .header_search {
        padding: 0 10px;
        margin: 0 10px;
    }

    .banner_mobile {
        height: 120vw;
        min-height: 55vh;
    }

    .footer_bottom {
        flex-direction: column;
        gap: 20px;
    }

    .footer_logo img {
        max-width: 180px;
    }

    .cart_sidebar {
        max-width: 100%;
        padding: 15px;
    }

    .header_dropdown .dropdown-menu {
        inset: auto 0px 100% auto !important;
    }
}