.prodctDtlSctin__left {
    position: relative;
    height: 100%;
}

.productSliderRow {
    --width: 80px;
    --padleft: 20px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.productSliderRow__left {
    flex: 0 0 var(--width);
    max-width: var(--width);
}

.productSliderRow__right {
    flex: 0 0 calc(100% - var(--width));
    max-width: calc(100% - var(--width));
    padding-left: var(--padleft);
}

.productSliderRow .slick-slide img {
    width: 100%;
}


.breadcrums {
    display: flex;
    list-style: none;
    gap: 5px;
}

.breadcrums li {
    font-size: 14px;
    color: #7E7E7E;
}

.breadcrums li a {
    font-size: inherit;
    color: inherit;
}

.breadcrums li+li {
    position: relative;
}

.breadcrums li+li::before {
    content: "/";
    display: inline-block;
    margin-right: 5px;
}

.productSliderRow__left img {
    border-radius: 10px;
    overflow: hidden;
    opacity: 0.5;
    border: 2px solid transparent;
    cursor: pointer;
}

.productSliderRow__left .slick-current img {
    opacity: 1;
    border-color: #082A6B;
}

.productSliderRow__right button.slick-arrow.slick-prev {
    left: 10px;
    z-index: 1;
}

.productSliderRow__right button.slick-arrow.slick-next {
    right: 10px;
    z-index: 1;
}

.productSliderRow__right button.slick-arrow.slick-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.productSliderRow__right .slick-slider .slick-arrow {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 1;
}

.productSliderRow__right .slick-slider .slick-arrow::before {
    content: "";
    display: inline-block;

    position: absolute;
    inset: 0;
    background-image: url(../../../assets/images/navigate_prev.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;
}


.productSliderRow__right .slick-slider .slick-arrow.slick-next::before {
    background-image: url(../../../assets/images/navigate_next.svg);

}

.prodctDtlSctin__right {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.selectionHolder {
    gap: 5px;
    flex-wrap: wrap;
}

.selectionHolder__box {}

.selectionHolder__box input {
    display: none;
}

.selectionHolder__box label {
    display: inline-flex;
    border: 1px solid rgba(8, 42, 107, 0.1);
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #6F6F6F;
    background: #fff;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    gap: 5px;
}

.selectionHolder__box input:checked+label {
    color: rgba(8, 42, 107, 1);
    border-color: rgba(8, 42, 107, 1);
    background: rgba(8, 42, 107, 0.10);


}

.selectionHolder__box .color {
    width: 14px;
    height: 14px;
    border-radius: 50%;

}

/*  */
.produtPagTitle {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font2);
    color: #7E7E7E;
}

.qty-input {
    color: #000;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.qty-input .product-qty,
.qty-input .qty-count {
    background: transparent;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    border: none;
    display: inline-block;
    min-width: 0;
    height: 30px;
    line-height: 1;
}

.qty-input .product-qty:focus,
.qty-input .qty-count:focus {
    outline: none;
}

.qty-input .product-qty {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.qty-input .product-qty::-webkit-outer-spin-button,
.qty-input .product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.qty-input .qty-count {
    padding: 0;
    cursor: pointer;
    width: 30px;
    font-size: 1.25em;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
    background: #EBEBEB;
    border-radius: 5px;
}

.qty-input .qty-count:before,
.qty-input .qty-count:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


.qty-input .qty-count--add:after {
    transform: rotate(90deg);
}

.qty-input .qty-count:disabled {
    color: #ccc;
    background: transparent;
    cursor: not-allowed;
    border-color: transparent;
}

.qty-input .qty-count:disabled:before,
.qty-input .qty-count:disabled:after {
    background: #ccc;
}

.buttonHolder {
    gap: 10px;
}

.buttonHolder__button {
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 12.5px 25px;
    font-family: var(--font2);
    flex-grow: 1;
}

.buttonHolder__button svg {
    margin-right: 5px;
}

.buttonHolder__blue {
    background: var(--primary);
    color: #fff;
    border-bottom-left-radius: 10px;
}

.buttonHolder__grey {
    background: #efefef;
    color: var(--primary);
    border-top-right-radius: 10px;
}

.buttonHolder__grey svg path {
    transition: 0.2s ease-in-out;
}

.buttonHolder__blue:hover {
    background: #efefef;
    color: var(--primary);
}

.buttonHolder__grey:hover {
    background: var(--primary);
    color: #fff;
}

.buttonHolder__grey:hover>svg * {
    fill: #fff;
}

.prodctDtlSctin__right {
    font-family: var(--font2);
}

.customEmbrodry {
    background: rgb(248, 141, 44, 0.1);
    padding: 15px 20px;
    border-radius: 10px;
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.customEmbrodry__center {
    flex-grow: 1;
}

.customEmbrodry__button {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    font-family: var(--font2);
    color: #fff;
    background: #F88D2C;
    border: 1px solid transparent;
}

.customEmbrodry__button:hover {
    background: transparent;
    color: #F88D2C;
    border-color: #F88D2C;
}

.reviewsbutton {
    font-weight: 500;
    font-size: 14px;

}

.productDetailboxes {
    max-width: 346px;
}

.productDetailboxes__img {
    margin-bottom: 24px;
    padding: 7px 6px;
}

.productDetailboxes__img img {
    display: inline-block;
    height: 38px;
}

.productDetailboxes__title {
    margin-bottom: 24px;
}

.productDetailboxes__cntnt {
    font-size: 14px;
}

.productDetailboxes__cntnt ul {
    list-style: disc;
    padding-left: 13px;
    margin-bottom: 24px;
}

.productDetailboxes__innerList {
    gap: 5px;
}

.ratingStar img {
    display: inline-block;
    width: 18px;
}

.reviewBox__images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 34px;
}

.reviewBox p {
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
    margin-bottom: 20px;
}

.reviewBox__images img {
    flex: 0 0 150px;
    max-width: 150px;
    border-radius: 10px;
}

.reviewBox__user {
    margin-bottom: 24px;
    --width: 40px;
    --g: 24px;
    gap: var(--g);
}

.reviewBox__userImg {
    flex: 0 0 var(--width);
    max-width: var(--width);

}

.reviewBox__userContent {
    flex: 0 0 calc(100% - calc(var(--width) + var(--g)));
    max-width: calc(100% - calc(var(--width) + var(--g)));
}

.reviewBox__userImg img {
    width: var(--width);
    height: var(--width);
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.reviewBox {
    padding: 40px 0;
}

.reviewBox+.reviewBox {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.prodctDtlSctin {
    padding: 20px 0 0;
}

.sizeModal .modal-dialog {
    --bs-modal-width: 550px;
}

.customizeModal .modal-dialog {
    --bs-modal-width: 468px;
}

.sizeTable tr td:first-child,
.sizeTable tr th:first-child {
    background: #EBEBEB;
}

.sizeTable tr td,
.sizeTable tr th {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.sizeTable.table>:not(caption)>*>* {
    border-color: #D3D3D3;
}

.modal-header.customHeader {
    flex-direction: column;
    padding-bottom: 0;
}

.customHeader__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-bottom: 7px;
}

.customHeader p {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
}

.selectedLogoDetails__left.d-flex .img {
    flex: 0 0 48px;
}

.selectedLogoDetails__left.d-flex .img img {
    width: 100%;
}

.selectedLogoDetails__left {
    gap: 20px;
}

.selectedLogoDetails {
    background: #F3F4F8;
    border-radius: 10px;
    padding: 19px 20px;
}

.selectedLogoDetails__right li {
    padding-inline: 20px;
}

.selectedLogoDetails__right li+li {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.selectedLogoDetails__right li button {
    font-size: 14px;
    color: #082A6B;
    font-weight: 600;
}

.placementBox__holder,
.customizeTab,
.customize__spacing {
    margin-bottom: 25px;

}

.placementBox+.placementBox {
    margin-top: 5px;

}

.customizeTab .selectionHolder__box input:checked+label {
    color: #fff;
    border-color: var(--primary);
    background: var(--primary);
}

.radioType2 {
    gap: 10px;
}

.radioType2 input {
    display: none;
}

.radioType2 label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
}

.radioType2 label[for="script"] {
    font-style: italic;
}

.radioType2 label * {
    transition: 0.2s ease-in-out all;
}

/* 
.radioType2 label::before,
.radioType2 label::after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
}

.radioType2 label::before {
    width: 20px;
    height: 20px;
    border: 3px solid #7E7E7E;
    left: 0;
    top: 0;
}

.radioType2 label::after {
    width: 9px;
    height: 9px;
    background: var(--primary);
    left: 5px;
    top: 6px;
    opacity: 0;
}

.radioType2 input:checked+label::before {
    border-color: var(--primary);
}

.radioType2 input:checked+label::after {
    opacity: 1;
} */

.radioType2 [type="radio"]:checked+label,
.radioType2 [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.radioType2 [type="radio"]:checked+label:before,
.radioType2 [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #7E7E7E;
    border-radius: 100%;
    background: #fff;
}

.radioType2 [type="radio"]:checked+label:after,
.radioType2 [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--primary);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.radioType2 [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.radioType2 [type="radio"]:checked+label:before {
    border-color: var(--primary);
}

.radioType2 [type="radio"]:checked+label:after {}

.customizePopInfo {
    --width: 15px;
    --gap: 13px;
    gap: var(--gap);
    background: #F0F0F0;
    border-radius: 10px;
    padding: 10px;
}

.customizePopInfo__icon {
    flex: 0 0 var(--width);
    max-width: var(--width);
}

.customizePopInfo__cntnt {
    flex: 0 0 calc(100% - var(--width) + var(--gap));
    max-width: calc(100% - calc(var(--width) + var(--gap)));
}

.customizeFooter__left {
    gap: 10px;
    color: var(--text2);
}

.imageUploadCstm input {
    display: none;
}

.imageUploadCstm label {
    border: 1px dashed #CED4E1;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    text-align: center;
}

.imageUploadCstm__img {
    padding: 7px;
}

.imageUploadCstm__img img {
    width: 34px;
}

.imageUploadCstm label>.font12.fontPrimary2 {
    max-width: 360px;
}

.customizeModal .selectedLogoDetails {
    border: 1px solid #E5E5E5;
    background: transparent;
}

.reviewSticky {
    position: sticky;
    z-index: 1;
    top: 0px;


}

.reviewSticky.reviewSticky__heading {
    background: #fff;
    bottom: 200px;
}



@media only screen and (max-width: 767px) {
    .prodctDtlSctin>.container>.row {
        gap: 20px;
    }
    .prodctDtlSctin__right { 
        gap: 18px;
    }
}

@media only screen and (max-width: 575px) {
    .productSliderRow {
        --width: 50px;
        --padleft: 15px;
    }

    .customEmbrodry__right {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .productSliderRow__right .slick-slider .slick-arrow {
        width: 30px;
        height: 30px;
    }

}

@media only screen and (max-width: 480px) {
    .selectedLogoDetails {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 10px;
    }
}