.about-us .banner_section .container {
  position: relative;
}

.about-us .banner_img {
  margin: 0 -40px;
  overflow: hidden;
  border-radius: 0 40px 0 40px;
}

.about-us .banner_content_1 {
  width: 100%;
  max-width: 412px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #fff;
  background-repeat: no-repeat;
  background-position: 90% 0%;
  padding: 30px 0;
}

.about-us .banner_content_1 h5 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 30px;
  color: #fff;
}

.about-us .banner_content_1 h6 {
  font-size: 30px;
  line-height: 1.1;
  color: gray;
}

.about-us .banner_content_1 p {
  font-size: 24px;
  font-family: var(--font2);
  color: var(--peach);
  font-weight: 400;
}

.about-us .heading_blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-us .heading_block {
  flex: 0 0 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin-bottom: 40px;
}

.about-us .heading_block img {
  max-width: 100%;
  height: auto;
}

.about-us .common_sec {
  padding: 60px 0;
}

@media screen and (max-width: 991px) {
  .about-us .heading_block {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 767px) {
  .about-us .heading_block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.about-us .collection_section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.about-us .container {
  margin: 0 auto;
  padding: 0 15px;
}

.about-us .heading_blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-us .heading_block {
  width: calc(33.33% - 30px);
  margin-bottom: 30px;
  text-align: center;
}

.about-us .block_image {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.about-us h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.about-us p {
  font-size: 16px;
  color: #666;
}
