.login_modal{
    background-color: #fff;
    position: fixed;
    inset: 0;
    z-index: 9999;
    transition: ease 0.5s;
    transform: translateY(-110%);
    opacity: 0;
    visibility: hidden;
}
.login_modal.show{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.login_modal_container{
    margin: 100px auto 20px;
    width: 100%;
    max-width: 300px;
}
.login_modal_header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.or_text{
    position: relative;
    border-top: 1px rgba(0,0,0,0.1) solid;
    text-align: center;
    margin: 30px 0 10px;
}
.or_text span{
    position: relative;
    padding-inline: 30px;
    background-color: #fff;
    top: -10px;
}
.signup_modal_col{
    padding: 60px 0;
    max-width: 420px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width:575px){
    .signup_modal_container{
        padding: 0 15px;
    }
    .login_modal_container{
        max-width: 100%;
        padding-inline: 15px;
    }
}