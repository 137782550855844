.cartOrderBox {
    border-radius: 10px;
}

.cartOrderBox__top {
    gap: 20px;
    flex-wrap: wrap;
}


.cartOrderBox .cartTable__preduct {
    --width: 80px;
    --g: 20px;
    gap: var(--g);
    flex-grow: 1;
}

.cartOrderBox__header {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    --cwidth: 100px;
}

.cartOrderBox__header .cartOrderBox__top {
    max-width: calc(100% - var(--cwidth));
}

.cartOrderBox__header .cartOrderBox__top>div {
    max-width: 180px;
    word-wrap: break-word;
}

.cartDtlSctin {
    flex: 1 0 0;
}

.cartOrderBox .orderStatusBox__top {
    --g: 0;
}

.cartOrderBox .cartTable__list:last-child {
    border-bottom: 0;
}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
    .cartOrderBox .cartTable__preduct {
        --width: 60px;
        --g: 15px;
    }


}

@media only screen and (max-width: 575px) {
    .cartOrderBox__top {
        gap: 15px;
        flex-direction: column;
    }

}

.pointer {
  font-size: 1.5em;
  color: #007bff; /* Customize the color as needed */
}

.status-badge {
  font-weight: bold;
  font-size: 1.2em;
}

.log-entry {
  border-left: 2px solid #007bff; /* Customize the color as needed */
  padding-left: 10px;
}

.log-time,
.log-remark {
  margin-left: 20px; /* Aligning the text properly */
}