.cartTotalbox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background: #FFC794;
    border-radius: 0 10px 0 10px;
    position: sticky;
    z-index: 1;
    top: 0px;
}

.cartTotalbox hr {
    margin: 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cartTotalbox__middle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text-red {
    color: #941826;
}

.cartTotalbox .right {
    text-align: right;
}

.cartTable__preduct {
    --width: 120px;
    --g: 20px;
    gap: var(--g);
    flex-grow: 1;

}

.cartTable__preductImg {
    flex: 0 0 var(--width);
    max-width: var(--width);
}

.cartTable__preductCntnt {
    flex: 0 0 calc(100% - calc(var(--width) + var(--g)));
    max-width: calc(100% - calc(var(--width) + var(--g)));
}

.cartTable__preductImg .cart_item_img img {
    border-radius: 10px;
}

.cartDtlSctin__headingholder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.cartTable__list {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    --g: 20px;
    gap: var(--g);
    --countWidth: 25px;
    --priceWidth: 180px;
}

.cartTable__count {
    flex: 0 0 var(--countWidth);
    max-width: var(--countWidth);
}

.cartTable__preduct {
    flex: 0 0 calc(100% - calc(var(--countWidth) + var(--priceWidth) + calc(var(--g) * 2)));
    max-width: calc(100% - calc(var(--countWidth) + var(--priceWidth) + calc(var(--g) * 2)));
}

.cartTable__price {
    flex: 0 0 var(--priceWidth);
    max-width: var(--priceWidth);
    text-align: right;
}

.cartTable__list:first-child {
    padding-top: 0;
}

.cartDtlSctin__heading {
    font-family: var(--font2);
    margin-bottom: 20px;
}

.cartDtlSctin {
    font-family: var(--font2);
    padding: 20px 0 0;
}

.cartAddressBox {
    border-radius: 10px;
    background-color: #EFEFEF;
    padding: 15px;
    --spacing: 40px;
}

.cartAddressBox-addMore {
    background: transparent;
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

.cartAddressBox button {
    padding-left: var(--spacing);
}

.cartAddressBox input {
    display: none;


}

.cartAddressBox label {
    display: block;
    position: relative;
    padding-left: var(--spacing);
    cursor: pointer;
    margin-bottom: 10px;
}

.cartAddressBox label::before,
.cartAddressBox label::after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
}

.cartAddressBox label::before {
    width: 20px;
    height: 20px;
    border: 3px solid #7E7E7E;
    left: 0;
    top: 0;
}

.cartAddressBox label::after {
    width: 10px;
    height: 10px;
    background: var(--primary);
    left: 5.1px;
    top: 4.9px;
    opacity: 0;
}

.cartAddressBox input:checked+label::before {
    border-color: var(--primary);
}

.cartAddressBox input:checked+label::after {
    opacity: 1;
}

.cartAddressRow {
    gap: 20px;
}

.cartAddressBox {
    flex: 0 0 300px;
    max-width: 300px;
}

.cartAddressBox-addMore>div {
    gap: 10px;
}

.cartSection {
    padding: 30px 0 20px;
}

.orderStatusBox {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 20px 0 20px;
    margin-bottom: 20px;
}

.orderStatusBox__topContent {
    padding-bottom: 20px;

}

.orderStatusBox__top {
    --g: 20px;
    --width: 48px;
    gap: var(--g);
}

.orderStatusBox__topIcon {
    flex: 0 0 var(--width);
    max-width: var(--width);
}

.orderStatusBox__topIcon img {
    width: 100%;
}

.orderStatusBox__topContent {
    flex: 0 0 calc(100% - calc(var(--width) + var(--g)));
    max-width: calc(100% - calc(var(--width) + var(--g)));
}

.orderStatusBox__bottom {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

.orderStatusBox__bottom {
    gap: 20px;
}

.orderStatusBox__button {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
    font-family: var(--font2);

}

.orderStatusBox__button.cancel {
    color: #FF0505;
}

.trackOrderList {
    padding: 20px 0;
    gap: 20px;
    font-family: var(--font2);
}

.trackOrderList+.trackOrderList {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.trackOrderbox__refreshButton {
    border: 0;
    background-color: transparent;
    color: var(--primary);
    font-size: 14;
    font-weight: 500;
    font-family: var(--font2);
}

.trackOrderbox__refreshButton img {
    margin-right: 5px;
}



@media only screen and (max-width: 991px) {
    .cartTable__preduct {
        --width: 60px;
        --g: 10px;
    }

    .cartTable__preduct {
        flex: 0 0 calc(100% - calc(var(--countWidth) + var(--g)));
        max-width: calc(100% - calc(var(--countWidth) + var(--g)));
    }

    .cartTable__price {
        flex: 0 0 100%;
        max-width: 100%;
        font-weight: 700;
        color: #000;
        text-align: right;
    }
    .cartTotalbox {      
        gap: 16px;
        padding: 20px 10px;
    }
}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 575px) {

    .orderStatusBox {
        padding: 15px;
    }

    .orderStatusBox__top {
        --g: 15px;
        --width: 30px;
    }

    .orderStatusBox__bottom {
        gap: 10px;
    }


    .cartTable__list {
        padding: 15px 0;
        gap: 10px;
    }

    .cartAddressBox {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .trackOrderList .left.text-light2.font14.med {
        white-space: nowrap;
    }
}

@media only screen and (max-width: 480px) {}